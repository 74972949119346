<template>
  <div class="AddCommodity">
    <div class="c-box">
      <div>
        <div class="menu2">
          <el-button
            :class="{ 'bu-bg': true, act: step == 1 }"
            @click="step = 1"
          >新建商品</el-button>
          <el-button :class="{'bu-bg':true,'act':step!==1}" style="margin-left:30px" @click="step=2">批量导入</el-button>
        </div>
        <el-form
          v-show="step == 1"
          ref="forData"
          label-width="125px"
          :model="forData"
          :rules="rules"
        >
          <el-row>
            <el-col :span="8">
              <el-form-item label="SKU：" prop="sku">
                <el-input
                  v-model="forData.sku"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="商品图片">
                <el-upload
                  :class="{ 'upload50 upload': true, hidden: hidden }"
                  accept=".jpg,.jpeg,.png"
                  :action="logourl"
                  list-type="picture-card"
                  :on-success="handleSuccess"
                  :before-upload="beforeUpload"
                  :on-remove="removeimg"
                >
                  <i class="el-icon-plus" />
                </el-upload>
              </el-form-item>
              <el-form-item label="尺寸(cm)：">
                <el-input
                  v-model="forData.length"
                  placeholder="长"
                  class="inputCc"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                />
                <el-input
                  v-model="forData.width"
                  placeholder="宽"
                  class="inputCc"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                />
                <el-input
                  v-model="forData.height"
                  placeholder="高"
                  class="inputCc"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                />
              </el-form-item>
              <el-form-item label="材质：" prop="materialQuality">
                <el-input
                  v-model="forData.materialQuality"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <!--             <el-form-item label="海关编码：" prop="hsCode">
              <el-input class="input190" v-model="forData.hsCode" placeholder="请输入" ></el-input>
            </el-form-item> -->
              <el-form-item label="销售价格：">
                <el-input
                  v-model="forData.salesPrice"
                  class="input190 input-with-select"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                >
                  <el-select
                    slot="append"
                    v-model="forData.salesPriceCurrency"
                    placeholder="请选择"
                  >
                    <el-option label="美元" value="1" />
                    <el-option label="人民币" value="2" />
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="丢货赔偿金额：">
                <el-input
                  v-model="forData.lossCompensationAmount"
                  class="input190 input-with-select"
                  placeholder="请输入内容"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                >
                  <el-select
                    slot="append"
                    v-model="forData.lcaCid"
                    placeholder="请选择"
                  >
                    <el-option label="美元" value="1" />
                    <el-option label="人民币" value="2" />
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="型号：">
                <el-input
                  v-model="forData.specification"
                  class="input190"
                  placeholder="请输入内容"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item
                label-width="130px"
                label="商品名称(中文)："
                prop="nameCn"
              >
                <el-input
                  v-model="forData.nameCn"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label-width="130px" label="条形码：">
                <el-input
                  v-model="forData.barcode"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label-width="130px" label="商品类型：" prop="types">
                <el-select
                  v-model="forData.types"
                  class="select190"
                  placeholder="请选择"
                >
                  <el-option label="普货" :value="1" />
                  <el-option label="特货" :value="2" />
                </el-select>
              </el-form-item>
              <el-form-item label-width="130px" label="用途：" prop="purpose">
                <el-input
                  v-model="forData.purpose"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="销售链接：">
                <el-input
                  v-model="forData.salesLink"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label-width="130px" label="品牌：">
                <el-input
                  v-model="forData.brand"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="申报要素：" prop="declareElements">
                <el-input
                  v-model="forData.declareElements"
                  class="input190"
                  type="textarea"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="商品名称(英文)：">
                <el-input
                  v-model="forData.nameEn"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="商品重量(KG)：" prop="weight">
                <el-input
                  v-model="forData.weight"
                  class="input190"
                  placeholder="请输入"
                  oninput="value=value.replace(/[^0-9.]/g,'')"
                />
              </el-form-item>
              <el-form-item class="btn_group_values">
                <el-button
                  size="mini"
                  round
                  type="primary"
                  @click="
                    dialogVisible = true;
                    btnType = '报关申报';
                  "
                >报关申报</el-button>
                <el-button
                  size="mini"
                  round
                  type="primary"
                  @click="
                    dialogVisible = true;
                    btnType = '报关文件';
                  "
                >报关文件</el-button>
                <el-button
                  size="mini"
                  round
                  type="primary"
                  style="margin-left: unset"
                  @click="
                    dialogVisible = true;
                    btnType = '清关申报';
                  "
                >清关申报</el-button>
                <el-button
                  size="mini"
                  round
                  type="primary"
                  @click="
                    dialogVisible = true;
                    btnType = '清关文件';
                  "
                >清关文件</el-button>

                <!-- <el-input class="input190 input-with-select" placeholder="请输入内容" v-model="forData.declaredValue" oninput="value=value.replace(/[^0-9.]/g,'')">
                <el-select v-model="forData.declaredCurrency" slot="append" placeholder="请选择">
                  <el-option label="美元" value="1"></el-option>
                  <el-option label="人民币" value="2"></el-option>
                </el-select>
              </el-input> -->
              </el-form-item>
              <el-form-item label="货物类型：">
                <el-input
                  v-model="forData.type"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
              <el-form-item label="海关编码：">
                <el-input
                  v-model="forData.hsCode"
                  class="input190"
                  placeholder="请输入"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row
            v-if="ruleForm.goodsServiceCostList && ruleForm.goodsServiceCostList.length"
          >
            <el-table :data="ruleForm.goodsServiceCostList || []" border>
              <el-table-column label="服务名" prop="serviceName" />
              <el-table-column label="附加费" prop="">
                <template #default="{ row }">
                  <span v-show="row.isNeedFee == 1">需要</span>
                  <span v-show="row.isNeedFee == 2">不需要</span>
                </template>
              </el-table-column>
              <el-table-column label="单价" prop="unitPrice" />
              <el-table-column label="币种" prop="">
                <template #default="{ row }">
                  {{ (currency.find(item => item.id == row.cid) || {}).currencyName }}
                </template>
              </el-table-column>
              <el-table-column label="单位" prop="">
                <template #default="{ row }">
                  <span v-show="row.unit == 1">公斤</span>
                  <span v-show="row.unit == 2">方</span>
                  <span v-show="row.unit == 3">票</span>
                  <span v-show="row.unit == 4">柜</span>
                  <span v-show="row.unit == 5">箱</span>
                </template>
              </el-table-column>
            </el-table>
            <!-- <el-col :span="24">
              <div class="renwuHeader" style="margin-left:56px;width:100%">
                <div style="width: 223px">服务名</div>
                <div style="width: 216px">附加费</div>
                <div style="width: 219px">单价</div>
                <div style="width: 223px">币种</div>
                <div>单位</div>
              </div>
              <div
                v-for="(obj, index) in ruleForm.goodsServiceCostList"
                :key="index"
                class="renwulist"
                style="margin-left:50px;margin-top:10px"
              >
                <div>
                  <el-select
                    v-model="ruleForm.goodsServiceCostList[index].serviceId"
                    placeholder="请选择"
                    style="width: 190px;"
                    size="mini"
                    disabled
                    @change="SurchargeOrNot($event, index)"
                  >
                    <el-option

                      v-for="item in ServiceGroup"
                      :key="item.id"
                      :label="item.codeName"
                      :value="item.id"
                    />
                  </el-select>
                </div>
                <div>
                  <el-select
                    v-model="ruleForm.goodsServiceCostList[index].isNeedFee"
                    size="mini"
                    disabled
                    style="width: 190px;margin-left:10px"
                  >
                    <el-option label="需要" value="1" />
                    <el-option label="不需要" value="2" />
                  </el-select>
                </div>
                <div>
                  <el-input
                    v-model="ruleForm.goodsServiceCostList[index].unitPrice"
                    oninput="value=value.replace(/[^0-9]/g,'')"
                    disabled
                    size="mini"
                    style="width: 190px;margin-left:10px"
                  />
                </div>
                <div>
                  <el-select
                    v-model="ruleForm.goodsServiceCostList[index].cid"
                    disabled
                    size="mini"
                    style="width: 190px;margin-left:10px"
                  >
                    <el-option
                      v-for="item in currency"
                      :key="item.id"
                      :label="item.currencyName"
                      :value="item.id"
                    />

                  </el-select>
                </div>
                <div>
                  <el-select
                    v-model="ruleForm.goodsServiceCostList[index].unit"
                    disabled
                    size="mini"
                    style="width: 190px;margin-left:10px"
                  >
                    <el-option label="公斤" :value="1" />
                    <el-option label="方" :value="2" />
                    <el-option label="票" :value="3" />
                    <el-option label="柜" :value="4" />
                    <el-option label="箱" :value="5" />
                  </el-select>
                </div>
              </div>
            </el-col> -->
          </el-row>

          <div style="text-align: center; margin: 50px">
            <el-button
              type="primary"
              style="width: 200px"
              @click="saveGood"
            >确认提交</el-button>
          </div>
        </el-form>
        <div v-show="step == 2">
          <el-row class="line">
            <el-col :span="21">
              <p class="icon">1</p>
              <span>下载模板批量导入商品</span>
            </el-col>
            <el-col :span="3">
              <el-button
                class="bhe36 buttom"
                round
                icon="el-icon-download"
                @click="down"
              >下载模板</el-button>
            </el-col>
          </el-row>
          <el-row class="line">
            <el-col :span="21">
              <p class="icon">2</p>
              <span>上传填写完整的模板信息</span>
              <el-upload
                ref="upload"
                class="upload-demo"
                action=""
                :on-change="handlePreview"
                :on-remove="handleRemove"
                :file-list="fileList"
                :auto-upload="false"
              >
                <el-button slot="trigger" size="small">选取文件</el-button>
              </el-upload>
            </el-col>
            <el-col :span="3">
              <el-button
                class="bhe36 buttom"
                round
                icon="el-icon-upload2"
                @click="importExcel"
              >上传</el-button>
            </el-col>
          </el-row>
        </div>
        <div v-show="step == 3">
          <p><el-button type="danger" @click="shancu">删除</el-button></p>
          <el-table
            ref="multipleTable"
            :data="tableData"
            :row-class-name="tableRowClassName"
            class="freightRate-table center"
            style="width: 100%; min-height: 550px"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column prop="sku" label="SKU" />
            <el-table-column prop="nameCn" label="商品名称(中文)" />
            <el-table-column prop="nameEn" label="商品名称(英文)" />
            <el-table-column label="商品图片">
              <template slot-scope="scope">
                <img :src="scope.row.imageUrl" width="100px">
              </template>
            </el-table-column>
            <el-table-column prop="barcode" label="条形码" />
            <el-table-column prop="weight" label="商品重量(KG)" />
            <el-table-column label="尺寸">
              <template slot-scope="scope">
                <span>{{ scope.row.length }}*{{ scope.row.width }}*{{
                  scope.row.height
                }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="typesName" label="商品类型" />
            <el-table-column prop="declaredValue" label="申报价值(USD)" />
            <el-table-column prop="hsCode" label="海关编码" />
            <el-table-column prop="salesLink" label="销售链接" />
            <el-table-column prop="salesPrice" label="销售价格" />
          </el-table>
          <p style="text-align: center">
            <el-button
              round
              type="primary"
              style="min-width: 120px"
              @click="confirmGood"
            >确定</el-button>
          </p>
        </div>
      </div>
    </div>
    <el-dialog title="申报信息" :visible.sync="dialogVisible" width="1000px">
      <div v-if="btnType === '报关申报'">
        <div class="renwuHeader">
          <i
            class="el-icon-circle-plus-outline con-i abc"
            @click="addtaskItemVOS('goodsCustomsValueList')"
          />
          <div style="width: 110px">国家</div>
          <div style="width: 210px">申报价值</div>
          <div style="width: 110px">货币</div>
        </div>
        <div
          v-for="(obj, index) in goodsCustomsValueList"
          :key="index"
          class="renwulist"
        >
          <i
            class="el-icon-remove-outline con-i"
            style="color: red"
            @click="deletetaskItemVOS(index, 'goodsCustomsValueList')"
          />
          <div>
            <el-select
              v-model="goodsCustomsValueList[index].countryCode"
              placeholder="国家"
              style="width: 90px"
              size="mini"
              :disabled="false"
            >
              <el-option
                v-for="item in getCountry"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </div>
          <div>
            <el-input
              v-model="goodsCustomsValueList[index].declaredValue"
              size="mini"
              style="width: 190px"
              placeholder="申报价值"
            />
          </div>
          <div>
            <el-select
              v-model="goodsCustomsValueList[index].declaredCurrency"
              :disabled="false"
              size="mini"
              style="width: 90px"
              placeholder="货币"
            >
              <el-option
                v-for="item in getCurrencyInfo"
                :key="item.id"
                :label="item.currencyCode"
                :value="item.currencyCode"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="btnType === '报关文件'">
        <div class="renwuHeader">
          <i
            class="el-icon-circle-plus-outline con-i abc"
            @click="addtaskItemVOS('goodsCustomsFileList')"
          />
          <div style="width: 110px">国家</div>
          <div style="width: 300px">说明</div>
          <div style="width: 210px">上传文件</div>
        </div>
        <div
          v-for="(obj, index) in goodsCustomsFileList"
          :key="index"
          class="renwulist"
        >
          <i
            class="el-icon-remove-outline con-i"
            style="color: red"
            @click="deletetaskItemVOS(index, 'goodsCustomsFileList')"
          />
          <div>
            <el-select
              v-model="goodsCustomsFileList[index].countryCode"
              placeholder="国家"
              style="width: 90px"
              size="mini"
              :disabled="false"
            >
              <el-option
                v-for="item in getCountry"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </div>
          <div>
            <el-input
              v-model="goodsCustomsFileList[index].describe"
              size="mini"
              :rows="2"
              type="textarea"
              style="width: 280px"
            />
          </div>
          <div>
            <el-upload
              ref="upload"
              class="upload-demo"
              :action="url"
              :show-file-list="false"
              :with-credentials="true"
              multiple
              :on-success="
                (response, file, fileList) => baoguanSuccess(index, response)
              "
            >
              <i class="el-icon-folder-add" style="font-size: 30px" />
            </el-upload>
          </div>
          <div class="fileList">
            <template
              v-for="(item, i) in goodsCustomsFileList[index].templateUrls"
            >
              <el-tag
                :key="i"
                style="width: 100px; margin-right: 10px"
                closable
                @close="fileListDelete(index, i, 'goodsCustomsFileList')"
              >
                <el-tooltip
                  placement="top"
                  effect="light"
                  :content="item.fileName"
                >
                  <a
                    :href="item.absolutePath"
                    class="fileClick"
                    target="_blank"
                  >{{ item.fileName }}</a>
                </el-tooltip>
              </el-tag>
            </template>
          </div>
        </div>
      </div>
      <div v-if="btnType === '清关申报'">
        <div class="renwuHeader">
          <i
            class="el-icon-circle-plus-outline con-i abc"
            @click="addtaskItemVOS('goodsClearanceValueList')"
          />
          <div style="width: 110px">国家</div>
          <div style="width: 210px">申报价值</div>
          <div style="width: 110px">货币</div>
        </div>
        <div
          v-for="(obj, index) in goodsClearanceValueList"
          :key="index"
          class="renwulist"
        >
          <i
            class="el-icon-remove-outline con-i"
            style="color: red"
            @click="deletetaskItemVOS(index, 'goodsClearanceValueList')"
          />
          <div>
            <el-select
              v-model="goodsClearanceValueList[index].countryCode"
              placeholder="国家"
              style="width: 90px"
              size="mini"
              :disabled="false"
            >
              <el-option
                v-for="item in getCountry"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </div>
          <div>
            <el-input
              v-model="goodsClearanceValueList[index].declaredValue"
              size="mini"
              style="width: 190px"
              placeholder="申报价值"
            />
          </div>
          <div>
            <el-select
              v-model="goodsClearanceValueList[index].declaredCurrency"
              :disabled="false"
              size="mini"
              style="width: 90px"
              placeholder="货币"
            >
              <el-option
                v-for="item in getCurrencyInfo"
                :key="item.id"
                :label="item.currencyCode"
                :value="item.currencyCode"
              />
            </el-select>
          </div>
        </div>
      </div>
      <div v-if="btnType === '清关文件'">
        <div class="renwuHeader">
          <i
            class="el-icon-circle-plus-outline con-i abc"
            @click="addtaskItemVOS('goodsClearanceFileList')"
          />
          <div style="width: 110px">国家</div>
          <div style="width: 300px">说明</div>
          <div style="width: 210px">上传文件</div>
        </div>
        <div
          v-for="(obj, index) in goodsClearanceFileList"
          :key="index"
          class="renwulist"
        >
          <i
            class="el-icon-remove-outline con-i"
            style="color: red"
            @click="deletetaskItemVOS(index, 'goodsClearanceFileList')"
          />
          <div>
            <el-select
              v-model="goodsClearanceFileList[index].countryCode"
              placeholder="国家"
              style="width: 90px"
              size="mini"
              :disabled="false"
            >
              <el-option
                v-for="item in getCountry"
                :key="item.id"
                :label="item.name"
                :value="item.code"
              />
            </el-select>
          </div>
          <div>
            <el-input
              v-model="goodsClearanceFileList[index].describe"
              size="mini"
              :rows="2"
              type="textarea"
              style="width: 280px"
            />
          </div>
          <div>
            <el-upload
              ref="upload"
              class="upload-demo"
              :action="url"
              :show-file-list="false"
              :with-credentials="true"
              multiple
              :on-success="
                (response, file, fileList) => qingguanSuccess(index, response)
              "
            >
              <i class="el-icon-folder-add" style="font-size: 30px" />
            </el-upload>
          </div>
          <div class="fileList">
            <template
              v-for="(item, i) in goodsClearanceFileList[index].templateUrls"
            >
              <el-tag
                :key="i"
                style="width: 100px"
                closable
                @close="fileListDelete(index, i, 'goodsClearanceFileList')"
              >
                <el-tooltip
                  placement="top"
                  effect="light"
                  :content="item.fileName"
                >
                  <a
                    :href="item.absolutePath"
                    class="fileClick"
                    target="_blank"
                  >{{ item.fileName }}</a>
                </el-tooltip>
              </el-tag>
            </template>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { upload } from '@/config/geteway';
import { saveGood, down, importExcel, confirmGood } from '@/api/goods.js';
import { findCountry, findCurrencyInfo } from '@/api/select.js';
import { findGoodsById, findServiceGroup } from '@/api/order.js';

export default {
  name: 'AddCommodity',
  props: {},
  data() {
    return {
      loading: false,
      dialogVisible: false,
      url: process.env.VUE_APP_FILEURL,
      getCountry: [],
      forData: {},
      btnType: '',
      goodsCustomsValueList: [],
      goodsCustomsFileList: [],
      goodsClearanceValueList: [],
      goodsClearanceFileList: [],
      getCurrencyInfo: [],
      ServiceGroup: [],
      fileList: [],
      selectList: [],
      currency: [],
      rules: {
        sku: [{ required: true, message: '内容不能为空！' }],
        nameCn: [{ required: true, message: '内容不能为空！' }],
        weight: [{ required: true, message: '内容不能为空！' }],
        types: [{ required: true, message: '请选择', trigger: 'change' }],
        declaredValue: [{ required: true, message: '内容不能为空！' }],
        materialQuality: [{ required: true, message: '内容不能为空！' }],
        purpose: [{ required: true, message: '内容不能为空！' }],
        // hsCode: [{ required: true, message: "内容不能为空！" }],
        declareElements: [{ required: true, message: '内容不能为空！' }]
        // clearanceCode: [{ required: true, message: "内容不能为空！" }],
      },
      logourl: upload,
      tableData: [],
      step: 1,
      ruleForm: {
        goodsServiceCostList: [
          // {
          //   serviceId: '',
          //   unitPrice: '',
          //   cid: '',
          //   unit: '4',
          //   isNeedFee: '2'
          // }
        ]
      }, // 弹窗信息
      selectedData: []
    };
  },
  computed: {
    hidden: function() {
      if (this.forData.imageUrl) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {},
  created() {
    this.init();
  },
  methods: {
    searchGoodsById() {
      findGoodsById({ goodId: this.forData.id }).then((res) => {
        this.goodsCustomsValueList = res.data.data.goodsCustomsValueList;
        this.goodsCustomsFileList = res.data.data.goodsCustomsFileList;
        this.goodsClearanceValueList = res.data.data.goodsClearanceValueList;
        this.goodsClearanceFileList = res.data.data.goodsClearanceFileList;
        this.ruleForm.goodsServiceCostList = res.data.data.goodsServiceCostList;
        // console.log(this.ruleForm.goodsServiceCostList);
      });
    },
    init() {
      if (this.$route.path === '/commodity/editCommodity') {
        this.forData = this.$store.state.commodity;
        this.searchGoodsById();
      }
      if (this.$route.params.import) {
        this.forData = this.$store.state.packingList;
      }
      // if(this.$route.query.id){
      //   this.getOreder()
      // }else{
      //   this.$router.replace({path:'/freightRate',});
      // }
      findCountry().then((res) => {
        if (+res.data.code === 0) {
          this.getCountry = res.data.data;
        }
      });
      findServiceGroup().then((res) => {
        if (+res.data.code === 0) {
          this.ServiceGroup = res.data.data;
        }
      });
      findCurrencyInfo().then((result) => {
        if (+result.data.code === 0) {
          this.currency = result.data.data;
        }
      });
    },
    tableRowClassName(row) {
      row.row.index = row.rowIndex;
    },
    // 上传成功函数
    handleSuccess(res) {
      if (res.code === 200) {
        this.$set(this.forData, 'imageUrl', res.data.filePath);
      }
    },
    beforeUpload(file) {
      // 上传限制
      const isJPG =
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG,JPEG,PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 删除图片
    removeimg() {
      this.$delete(this.forData, 'imageUrl');
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      this.fileList = [{ ...file }];
      console.log(this.fileList);
    },
    saveGood() {
      // 保存商品
      this.$refs.forData.validate((valid) => {
        if (valid) {
          this.forData = {
            ...this.forData,
            goodsCustomsValueList: this.goodsCustomsValueList,
            goodsCustomsFileList: this.goodsCustomsFileList,
            goodsClearanceValueList: this.goodsClearanceValueList,
            goodsClearanceFileList: this.goodsClearanceFileList
          };
          if (this.loading) return;
          this.loading = true;
          saveGood(this.forData)
            .then((result) => {
              if (result.data.code === 0) {
                let message;
                if (this.$route.path !== '/commodity/editCommodity') {
                  this.forData = {};
                  message = '新增成功';
                } else {
                  message = '修改成功';
                }
                this.$message({ message, type: 'success' });
                this.$refs.forData.resetFields();
                this.loading = false;
              } else {
                this.$message({ message: result.data.msg, type: 'warning' });
                this.loading = false;
              }
            })
            .catch(() => {
              this.$message({ message: '请求失败', type: 'warning' });
              this.loading = false;
            });
        }
      });
    },
    confirmGood() {
      // 确认商品
      confirmGood(this.tableData)
        .then((result) => {
          if (result.data.code === 0) {
            this.$message({ message: result.data.data, type: 'success' });
            this.tableData = [];
            this.fileList = [];
            this.step = 2;
          } else {
            this.$message({ message: result.data.msg, type: 'warning' });
          }
        })
        .catch(() => {
          this.$message({ message: '请求失败', type: 'warning' });
        });
    },
    importExcel() {
      // 上传文件
      if (this.fileList.length > 0) {
        const formData = new FormData();
        formData.append('file', this.fileList[0].raw);
        importExcel(formData)
          .then((result) => {
            if (result.data.code === 0) {
              this.step = 3;
              this.tableData = result.data.data;
            } else {
              this.$message({ message: result.data.msg, type: 'warning' });
            }
          })
          .catch(() => {
            this.$message({ message: '请求失败', type: 'warning' });
          });
      } else {
        this.$message({ message: '请选择上传文件', type: 'warning' });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    shancu() {
      // 拿到选中的数据
      const val = this.multipleSelection;
      // 如果选中数据存在
      if (val) {
        // 将选中数据遍历
        val.forEach((val) => {
          this.tableData.forEach((v, i) => {
            // 如果选中数据和源数据的某一条唯一标识符相等，删除对应的源数据
            if (val.index === v.index) {
              this.tableData.splice(i, 1);
            }
          });
        });
      }
      // 清除选中状态
      this.$refs.multipleTable.clearSelection();
    },
    goto(route) {
      this.$router.push({ path: route });
    },
    down() {
      window.location.href = down;
    },

    addtaskItemVOS(val) {
      if (val === 'goodsCustomsValueList') {
        this.goodsCustomsValueList.push({
          countryCode: '',
          declaredValue: '',
          declaredCurrency: ''
        });
      }
      if (val === 'goodsCustomsFileList') {
        this.goodsCustomsFileList.push({
          countryCode: ''
        });
      }
      if (val === 'goodsClearanceValueList') {
        this.goodsClearanceValueList.push({
          countryCode: '',
          declaredValue: '',
          declaredCurrency: ''
        });
      }
      if (val === 'goodsClearanceFileList') {
        this.goodsClearanceFileList.push({
          countryCode: ''
        });
      }
    },
    deletetaskItemVOS(index, val) {
      if (val === 'goodsCustomsValueList') {
        this.goodsCustomsValueList.splice(index, 1);
      }
      if (val === 'goodsCustomsFileList') {
        this.goodsCustomsFileList.splice(index, 1);
      }
      if (val === 'goodsClearanceValueList') {
        this.goodsClearanceValueList.splice(index, 1);
      }
      if (val === 'goodsClearanceFileList') {
        this.goodsClearanceFileList.splice(index, 1);
      }
    },
    baoguanSuccess(index, res) {
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: res.msg
        });
        if (!this.goodsCustomsFileList[index].templateUrls) {
          this.$set(this.goodsCustomsFileList[index], 'templateUrls', []);
        }
        this.goodsCustomsFileList[index].templateUrls.push(res.data);
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        });
      }
    },
    qingguanSuccess(index, res) {
      if (res.code === 200) {
        this.$message({
          type: 'success',
          message: res.msg
        });
        if (!this.goodsClearanceFileList[index].templateUrls) {
          this.$set(this.goodsClearanceFileList[index], 'templateUrls', []);
        }
        this.goodsClearanceFileList[index].templateUrls.push(res.data);
      } else {
        this.$message({
          type: 'error',
          message: res.msg
        });
      }
    },
    fileListDelete(index, i, val) {
      this[val][index].templateUrls.splice(i, 1);
    }
  }
};
</script>

<style scoped>
.buttom {
  min-width: 140px;
  background: #2aba35;
  color: #fff;
}
.upload-demo {
  display: inline-block;
}
.upload {
  display: inline-block;
}
.inputCc {
  width: 60px;
  margin-right: 9px;
}
.input190 {
  width: 190px;
}
.menu2 {
  margin: 20px 0 30px;
}
.bu-bg {
  color: #507eff;
  border-color: #81a2ff;
  background: #eef2ff;
}
.act {
  background: #507eff;
  color: #fff;
  border: 0;
}
.icon {
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-size: 16px;
  border-radius: 18px;
  background: #cad5f5;
  display: inline-block;
  color: #fff;
}
.line {
  display: flex;
  font-size: 16px;
  width: 100%;
  align-items: center;
  height: 70px;
  background: #f4f6fa;
  margin-bottom: 20px;
}
.line p {
  margin: 0;
}
.line .icon {
  margin: 0 20px;
}

.renwulist {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  margin-left: 100px;
}
.renwulist div {
  padding-right: 10px;
}
.renwuHeader {
  margin-left: 100px;
  width: 800px;

}
.renwuHeader div {
  display: inline-block;
}

.con-i {
  font-size: 18px;
  cursor: pointer;
  color: #409eff;
  margin-right: 10px;
}
.fileClick {
  width: 100%;
  vertical-align: middle;
  text-decoration: none;
  color: #409eff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  padding: 0 10px 0 0;
  box-sizing: border-box;
}
.fileList /deep/ .el-tag__close.el-icon-close {
  right: 10px;
}
.btn_group_values /deep/ .el-form-item__content {
  text-align: center;
  width: 50%;
}

.el-input  /deep/.is-disabled .el-input__inner {
  background-color: white !important;
}
.el-select  /deep/.el-input.is-disabled .el-input__inner {
  cursor: not-allowed;
  background: white;
}

</style>
